/* eslint-disable camelcase */
/* eslint-disable no-nested-ternary */
import { useFeedbackHandler, useSearchQuery, useSetUserAccess } from '+hooks';
import APIRequest from '+services/api-services';
import Copyable from '+shared/Copyable';
import SearchComponent from '+shared/Search';
import Table from '+shared/Table';
import { capitalize, capitalizeRemovedash, isAllowed, switchStatus } from '+utils';
import React from 'react';
import { useQuery } from 'react-query';
import { Route, Switch } from 'react-router-dom';
import { UserAccess } from 'src/types';
import VirtualAccountDetails from './VirtualAccountDetails';
import './index.scss';

type Merchant = {
  name: string;
  id: number;
};

export interface VirtualAccountList {
  status: string;
  account_number: string;
  account_name: string;
  provider_name: string;
  BvnkNG_reference: string;
  account_reference: string;
  bank_name: string;
  merchant: Merchant;
}

export interface AnyloadingTypes {
  active: boolean;
  suspended: boolean;
  deactivated: boolean;
  deleted: boolean;
}
export interface Paging {
  total_items: number;
  page_size: number;
  current: number;
  count: number;
  next: number;
}
export interface Links {
  href: string;
  rel: string;
  method: string;
}
export interface ActiveList {
  data: VirtualAccountList[];
  paging: Paging;
  type: string;
  links: Links[];
}

const api = new APIRequest();

const VirtualAccounts = () => {
  const { feedbackInit } = useFeedbackHandler();
  const searchQuery = useSearchQuery();
  const virtualAccountTypes = ['active', 'suspended', 'deactivated', 'deleted'];
  const activeTab = searchQuery.value.tab || 'active';
  const currentPage = searchQuery.value.page || 1;
  const currentLimit = searchQuery.value.limit || 10;
  const search = searchQuery.value.search || '';

  const userAccess: UserAccess = useSetUserAccess();

  const buffer: Array<ActiveList> = [];
  const anyLoading: AnyloadingTypes | Record<string, unknown> = {};
  const getTabDetails = (tab: string): ActiveList | undefined => buffer.find((data: { type: string }) => data.type === tab);
  const computePage = (tab: string): number => (tab === activeTab ? currentPage : 1);
  const computeLimit = (tab: string): number => (tab === activeTab ? currentLimit : 10);

  virtualAccountTypes.forEach((type) => {
    const { data, isFetching } = useQuery(
      [`${type.toUpperCase()}_VIRTUAL_ACCOUNTS`, computePage(type), computeLimit(type), search],
      () => api.getVirtualBankAccounts(type, computePage(type), computeLimit(type), search),
      {
        keepPreviousData: true,
        onError: () => {
          feedbackInit({
            message: 'There has been an issue while fetching your virtual accounts',
            type: 'danger'
          });
        }
      }
    );
    anyLoading[type] = isFetching;
    buffer.push({ type, ...data });
  });

  const virtualAccountTableData = {
    className: '--vba-history',
    rowURL: isAllowed(userAccess, ['virtual_account_details.view']) ? '/dashboard/virtual-accounts' : `/dashboard/access-denied`,
    rowKey: isAllowed(userAccess, ['virtual_account_details.view']) ? 'BvnkNG_reference' : '',
    emptyStateHeading: 'No virtual bank accounts yet',
    emptyStateMessage: 'There are no merchant virtual bank accounts',
    annotations: 'account(s)',
    fields: (iter) => ({
      style: { background: iter.status === 'suspended' ? '#FFFAF0' : '' },
      data: {
        status: (
          <>
            <span className={`status-pill smaller ${iter.status === 'deactivated' ? 'gray' : switchStatus(iter.status)}`} />
            <span>{capitalize(iter?.status || '')}</span>
          </>
        ),
        account: (
          <>
            <span style={{ fontWeight: 600, color: '#7447FD' }}>{iter?.account_number}</span>{' '}
            {iter?.provider_name && <span> _ {capitalizeRemovedash(iter?.provider_name)}</span>}
          </>
        ),
        account_name: iter.account_name,
        merchant: iter.merchant?.name,
        account_reference: <Copyable text={iter.account_reference} showOnHover />
      }
    })
  };

  const tableDataKeys = Object.keys(virtualAccountTableData.fields({}).data);

  return (
    <div className="content-i">
      <div className="content-box">
        <section className="os-tabs-w">
          <div className="nav-tabs os-tabs-controls os-tabs-complex">
            <ul className="nav">
              {virtualAccountTypes.map((tab) => (
                <li
                  className="nav-item"
                  key={tab}
                  style={{ position: 'relative' }}
                  onClick={() => {
                    searchQuery.setQuery({ tab, page: 1 }, true);
                  }}
                  onKeyUp={() => {
                    searchQuery.setQuery({ tab, page: 1 }, true);
                  }}
                  role="tab"
                  tabIndex={0}
                >
                  {tab === 'suspended' && <div className="new-messages-count">{getTabDetails('suspended')?.paging?.total_items || 0}</div>}
                  <button type="button" className={`nav-link ${activeTab === tab && 'active'}`}>
                    {capitalize(tab)}
                  </button>
                </li>
              ))}
            </ul>
            <div className="element-actions d-sm-block">
              <div className="search-filter-box mt-0">
                <SearchComponent handleSearchQuery={(value) => searchQuery.setQuery({ search: value })} />
              </div>
            </div>
          </div>
        </section>
        <Table
          header={null}
          hasFilter={false}
          className={virtualAccountTableData.className || ''}
          tableHeadings={tableDataKeys}
          loading={anyLoading[activeTab]}
          data={getTabDetails(activeTab)?.data}
          renderFields
          hasPagination
          annotation={virtualAccountTableData.annotations}
          current={parseInt(currentPage, 10)}
          rowKey={virtualAccountTableData.rowKey}
          rowURL={virtualAccountTableData.rowURL}
          pageSize={getTabDetails(activeTab)?.paging ? getTabDetails(activeTab)?.paging.page_size : 0}
          totalItems={getTabDetails(activeTab)?.paging ? getTabDetails(activeTab)?.paging.total_items : 0}
          actionFn={(page: number) => searchQuery.setQuery({ page })}
          limitAction={(limit: number) => searchQuery.setQuery({ limit })}
          emptyStateHeading={virtualAccountTableData.emptyStateHeading || ''}
          tableWrapperClassName="table-responsive"
          emptyStateMessage={virtualAccountTableData.emptyStateMessage || ''}
        >
          {virtualAccountTableData?.fields}
        </Table>
      </div>
    </div>
  );
};

export default function VirtualBankAccounts() {
  return (
    <div className="virtual-accounts__container">
      <Switch>
        <Route exact path="/dashboard/virtual-accounts" component={VirtualAccounts} />
        <Route path="/dashboard/virtual-accounts/:id">
          <VirtualAccountDetails />
        </Route>
      </Switch>
    </div>
  );
}
