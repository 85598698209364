/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-children-prop */
import VBAIcon from '+assets/img/dashboard/vba-icon.svg';
import { useFeedbackHandler, useSearchQuery } from '+hooks';
import APIRequest from '+services/api-services';
import Copyable from '+shared/Copyable';
import LoadingPlaceholder from '+shared/LoadingPlaceHolder';
import SecondaryDetails from '+shared/SecondaryDetails';
import Table from '+shared/Table';
import { capitalize, capitalizeRemovedash, formatAmount, getDate, getTime, history, switchStatus } from '+utils';
import React from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import '../index.scss';

const api = new APIRequest();

export default function VirtualBankAccountDetails() {
  const { id } = useParams();
  const { feedbackInit } = useFeedbackHandler();
  const searchQuery = useSearchQuery();
  const page = searchQuery.value.page || 1;
  const limit = searchQuery.value.limit || 10;

  const { data: detailsData, isLoading: isFetchingDetails } = useQuery(
    `${id?.toUpperCase()}_DETAILS`,
    () => api.getVirtualBankAccount(id),
    {
      onError: () => {
        feedbackInit({
          message: "There has been an error this account's information",
          type: 'danger'
        });
      }
    }
  );

  const { data: vbaTrxns, isFetching } = useQuery(
    [`${id?.toUpperCase()}_TRANSACTIONS`, page, limit],
    () => api.getVirtualBankAccountTransactions(id, page, limit),
    {
      keepPreviousData: true,
      onError: () => {
        feedbackInit({
          message: "There has been an error getting this account's transactions",
          type: 'danger'
        });
      }
    }
  );

  const paging = vbaTrxns?.paging || {};
  const transactions = vbaTrxns?.data || [];
  const data = detailsData?.data || {};

  const vbaDetails = [
    {
      'Account Number': data.account_number || 'Not Available',
      'Account Name': data.account_name || 'Not Available',
      Bank: `${capitalizeRemovedash(data?.bank_name || 'Not Available')}`,
      'Account Status': (
        <>
          <span
            className={`status-pill smaller status-pill-smaller ${data.account_status === 'deactivated' ? 'gray' : switchStatus(data.account_status)
              }`}
          />{' '}
          {capitalize(data.account_status || 'Not Available')}
        </>
      ),
      'Unique ID': <Copyable text={data.unique_id} showOnHover textModifier={(x) => x?.toUpperCase()} />,
      'Total Amount Received': `${formatAmount(data.total_transacted_amount || 0)} ${data.currency || 'NGN'}`
    },
    {
      Merchant: data.merchant?.name || 'Not Available',
      'Customer Name': data.customer?.name || 'Not Available',
      'Customer Email': data.customer?.email || 'Not Available',
      'Created At': `${getDate(data.created_at)}`,
      'Account Reference': <Copyable text={data.account_reference} showOnHover />
    }
  ];

  const vbaTransactions = {
    className: '--vba-trxns',
    rowURL: '/dashboard/pay-ins',
    rowKey: 'reference',
    emptyStateHeading: 'No transactions yet',
    emptyStateMessage: 'There are no transactions for this account yet.',
    annotations: 'transaction(s)',
    fields: (iter) => ({
      data: {
        transaction_date: (
          <>
            <span>{getDate(iter.created_at)}</span>
            <span className="annotation" style={{ marginLeft: '5px' }}>
              {getTime(iter.created_at)}
            </span>
          </>
        ),
        'Transaction ID': (
          <span style={{ color: '#7447fd', fontWeight: '500', textTransform: 'uppercase' }}>{iter.payment?.reference}</span>
        ),
        payer: `${capitalize(iter?.payer_bank_account?.account_name || 'Not Available')}`,
        amount: (
          <span>
            <span style={{ fontWeight: '600' }}>{formatAmount(iter.amount)} </span>
            {iter?.currency || 'NGN'}
          </span>
        )
      }
    })
  };

  const tableDataKeys = Object.keys(vbaTransactions.fields({}).data);

  return (
    <div className="content-box">
      <div className="row mb-3">
        {!isFetchingDetails && (
          <button type="button" className="btn btn-link" onClick={() => history.goBack()}>
            <i className="phosphor-icon phosphor-icon-arrow-left" />
            <span style={{ fontSize: '13px', fontWeight: '500' }}>Go Back</span>
          </button>
        )}
      </div>
      <div className="row">
        <div className="col-sm-12">
          <div className="element-wrapper">
            <div className="content-details-box">
              <div className="content-details-head --content-details">
                {!isFetchingDetails && (
                  <div className="content-details-title">
                    <div>
                      <img src={VBAIcon} alt="" />
                    </div>
                    <div className="cd-title ml-3">
                      <h3 className="cd-maintitle" aria-label="Account Number">
                        {data?.account_number}
                      </h3>
                      <p className="cd-subtitle">{capitalizeRemovedash(data?.bank_name || 'Not Available')}</p>
                    </div>
                  </div>
                )}
                <div className="virtual-account-summary">
                  {data.account_status === 'suspended' && <section className="vba-banner">This account has been suspended!</section>}
                  {data.account_status === 'deleted' && (
                    <section className="vba-banner deleted">This account has been deleted and is no longer in use!</section>
                  )}
                  {data.account_status === 'deactivated' && (
                    <section className="vba-banner deactivated">This account has been deactivated!</section>
                  )}
                  {isFetchingDetails ? (
                    <LoadingPlaceholder type="text" content={4} />
                  ) : (
                    <SecondaryDetails title="Account Summary" data={vbaDetails} />
                  )}
                </div>
              </div>

              <Table
                header={`Transactions (${transactions.length})`}
                className={vbaTransactions.className || ''}
                loading={isFetching}
                data={transactions}
                renderFields
                hasPagination
                tableHeadings={tableDataKeys}
                annotation={vbaTransactions.annotations}
                current={parseInt(page, 10)}
                rowKey={vbaTransactions.rowKey}
                rowURL={vbaTransactions.rowURL}
                pageSize={paging?.page_size || 0}
                totalItems={paging?.total_items || 0}
                actionFn={(current) => searchQuery.setQuery({ page: current })}
                emptyStateHeading={vbaTransactions.emptyStateHeading || ''}
                tableWrapperClassName="table-responsive"
                emptyStateMessage={vbaTransactions.emptyStateMessage || ''}
                hasFilter={false}
              >
                {vbaTransactions.fields}
              </Table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
